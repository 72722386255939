import React from "react";
import WalkinForm from "../containers/WalkIn/CustomerForm/CustomerFormSteps";
import Layout from "../components/Layout/Layout";
import UpdateButton from "../common/components/updateButton/UpdateButton";
import withErrorHandler from "../hoc/withErrorHandler/withErrorHandler";

const Customer = () => {
  return (
    <Layout customTitle="WalkIn Form | ">
      <UpdateButton message="This application has been updated. It will be reloaded soon.">
        <WalkinForm />;
      </UpdateButton>
    </Layout>
  );
};

export default withErrorHandler(Customer);
